<!--
 * @Author: wangwei
 * @Date: 2020-12-29 15:56:16
 * @LastEditTime: 2023-01-17 23:41:51
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 订单支付页面
 * @FilePath: /java-pc/src/views/buy/pay.vue
-->
<template>
    <div id="pay">
        <div class="bg">
            <!-- 签署协议start -->
            <div class="pay_method">
                <div class="bank_bottom_Form">

                    <!-- 绑定银行卡 start -->
                    <el-form :model="bankForm" label-width="120px" v-if="isgoBnak == 4">
                        <div class="main">
                        <div class="otherPay">协议支付绑卡 </div>
                        <!-- <UnBind v-if="true"></UnBind  > -->
                        <el-form-item label="支付密码">
                            <el-col :span="7" style="float: none;">
                                <el-input v-model="payPwd" placeholder="请输入支付密码" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="短信验证码:">
                            <el-col :span="7" style="float: none;">
                                <el-input v-model="testnum" placeholder="请输入短信验证码" width="120px" />
                                <div class="timeOut" style="cursor: pointer" @click="getTestNumss(item)">{{ isFlag == true ? descNum : '获取验证码' }}</div>
                                <!-- <div class="timeOut">120s后获取</div> -->
                            </el-col>
                        </el-form-item>
                        <div class="next" >
                            <div @click="payNow">立即支付</div>
                        </div>
                    </div>
                    </el-form>
                    <!-- 绑定银行卡 end -->
                    <!-- 打款验证 start -->
                    <el-form :model="bankForm" label-width="120px" v-if="isgoBnak == 3">
                        <div class="main">
                        <div class="otherPay"> <img />协议支付绑卡</div>
                        <el-form-item label="付款人账号:" style="margin-left: 0;">
                            <el-col style="float: none;width: 200px;">
                                <el-input v-model="bankForm.payerAcctNo" placeholder="请输入付款人账号" width="120px" type="number" minlength="5" maxlength="30" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="付款户名"  style="margin-left: 0;">
                            <el-col style="float: none;width: 200px;">
                                <el-input v-model="bankForm.payerAcctName" placeholder="请输入付款户名" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="证件类型:"  style="margin-left: 0;">
                            <el-col style="float: none;width: 200px;">
                                <el-select v-model="bankForm.payerGlobalType" placeholder="请选择证件类型">
                                    <el-option label="身份证" value="01" />
                                    <el-option label="港澳回乡证" value="3" />
                                    <el-option label="台胞证" value="5" />
                                    <el-option label="中国护照" value="4" />
                                    <el-option label="外国护照" value="19" />
                                </el-select>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="证件号码"  style="margin-left: 0;">
                            <el-col style="float: none;width: 200px;">
                                <el-input v-model="bankForm.payerGlobalId" placeholder="请输入证件号码" type="number" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="预留手机号"  style="margin-left: 0;">
                            <el-col style="float: none;width: 200px;">
                                <el-input v-model="bankForm.payerReserverMobile" placeholder="请输入手机号" type="number" />
                            </el-col>
                        </el-form-item>
                        <el-form-item label="短信验证码:"  style="margin-left: 0;">
                            <el-col style="float: none;width: 200px;">
                                <el-input v-model="messageCheckCode" placeholder="请输入短信验证码" width="120px" type="number" />
                                <!-- <div class="timeOut" @click="sendMsg">获取验证码</div> -->
                                <div class="timeOut" style="cursor: pointer" @click="getTestNum">{{ isFlag == true ? descNum : '获取验证码' }}</div>
                                <!-- <div class="timeOut">120s后获取</div> -->
                            </el-col>
                        </el-form-item>
                        <div class="next">
                            <div @click="bindCard">立即申请</div>
                        </div>
                    </div>
                    </el-form>
                    <div v-if="isgoBnak == 2">
                        <div class="bank_content">
                            <!-- <div class="bank_content_top">我的银行卡</div> -->
                            <div class="card_list">
                                <div class="item" v-for="item in bankListSelf">
                                    <div class="bank_content_bottom">
                                        <div>{{item.bankName}}</div>
                                        <div>{{item.bankNo}}</div>
                                    </div>
                                    <div :class="[bankFlag == item.cardId? 'unbinding': 'unbinding2']" @click="checkBank(item)"></div>
                                    <input type="text" name="" id="" class="itemInput" v-model="bankMessage">
                                    <div class="timeOut2" style="cursor: pointer" @click="getTestNums(item)">{{ isFlag == true ? descNum : '获取验证码' }}</div>
                                </div>
                            </div>
                            <div class="activation" style="cursor: pointer" @click="activationCard">立即激活</div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- 签署协议end -->
            <!-- <div class="no_payMethod" v-else>
                <img src="@/assets/member/member_empty.png" alt="">
                <p>暂无可用的支付方式，平台正在紧急处理中～</p>
            </div> -->
        </div>
    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
import { ElMessage, ElInput } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Encrypt, Decrypt} from '@/utils/common.js'
// import UnBind from "./unbind.vue";
export default {
    name: "Pay",
    components: {
        ElInput,
        // UnBind
    },
    setup() {
        // 签署协议
        const bankForm = ref({
            // messageCheckCode: '', //短信验证码
            payerAcctName: '', //付款用户名
            payerAcctNo: '', //付款人账号
            payerGlobalId: '', //证件号码
            payerGlobalType: '', //证件类型
            payerReserverMobile: '',
            // resource: '',
            // desc: '',
        })
        const  messageCheckCode = ref('')
        let isBinding = ref(true)
        // 签署协议end
        const ifcart = ref('')
        // const balance = require("../../assets/buy/balance.png");
        // const otherPay = require("../../assets/buy/otherPay.png");
        // const ali = require("../../assets/buy/ali.png");
        // const wechat = require("../../assets/buy/wechat.png");
        // const wx_pay_tip = require("../../assets/buy/wx_pay_tip.png");
        const route = useRoute();
        const router = useRouter();
        const store = useStore()
        const { proxy } = getCurrentInstance();
        const pay_method = reactive({ data: [] });
        const pay_info = reactive({ data: {} });
        const paySn = ref("");
        const current_pay_method = reactive({ data: {} });
        const balance_list = reactive({ data: [] });
        const other_pay_list = reactive({ data: [] });
        const buy_name_list = ref("");
        const password = ref("");
        const show_wx_pay = ref(false);
        const wxPayQrImg = ref('');//微信支付二维码
        const timer = ref(0);//定时器
        const show_detail = ref(true)
        const hasPayMethod = ref(true)
        const configInfo = ref(store.state.configInfo)
        const memberInfo = ref(store.state.memberInfo);
        // const defaultImg = ref('this.src="' + require('../../assets/common_top_logo.png') + '"')
        const isgoBnak = ref(3)//判断是否去绑卡页面
        console.log(memberInfo.value)
        //获取支付方式
        const getPayMethod = () => {
            proxy
                .$get("v3/business/front/orderPay/payMethod", {
                    source: "pc",
                    type: 1
                })
                .then(res => {
                    if (res.state == 200) {
                        hasPayMethod.value = res.data.length > 0 ? true : false
                        res.data.map(item => {
                            if (item.payMethod == "balance") {
                                balance_list.data.push(item);
                            } else {
                                other_pay_list.data.push(item);
                            }
                        });
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //获取订单支付数据
        const getPayInfo = () => {
            proxy
                .$get("v3/business/front/orderPay/payInfo", {
                    paySn: route.query.paySn,
                    payFrom: route.query.payFrom
                })
                .then(res => {
                    if (res.state == 200) {
                        pay_info.data = res.data;
                        buy_name_list.value = res.data.goodsNameList.join(" + ");
                    } else if (res.state == 267) {
                        ElMessage.success(res.msg + ',2s后自动跳转订单列表');
                        //清除定时器
                        if (timer.value) {
                            clearInterval(timer.value);
                            timer.value = 0;
                        }
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 2000)
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const changePayMethod = pay_method => {
            current_pay_method.data = pay_method;
        };
        // 获取银行卡列表
        const bankListSelf = ref([])
        const getBankInfo = async () => {
            console.log(1)
            proxy.$get('/v3/member/front/member/cardList').then(res => {
                if (res.state == 200) {
                    // ElMessage.success(res.msg)
                    // console.log(res)
                    bankListSelf.value = res.data
                } else {
                    ElMessage.warning(res.msg)
                }
            })

        }
        getBankInfo()
        // 协议支付绑卡
        // 获取验证码
        const descNum = ref(120)
        let isFlag = ref(false)
        const getTestNum = () => {
            if (isFlag.value == true) {
                return
            }
            isFlag.value = true
            const timedesc = setInterval(() => {
                descNum.value -= 1
            }, 1000);
            setTimeout(() => {
                isFlag.value = false
                descNum.value = 120
                clearInterval(timedesc)
            }, 6000)

            // let params = {}       
            let params = {};
            params= bankForm.value;     
            // params.
            // proxy.$post('/v3/member/front/balanceRecharge/sendSMS', params).then(res => {
                proxy.$post('/v3/member/front/balanceRecharge/sendSMS', JSON.stringify(params), "json").then(res => {
                if (res.state == 200) {
                    ElMessage.success(res.msg)
                } else {
                    ElMessage.warning(res.msg)
                }
            })

        }
        const getTestNums = (item) => {
            if (isFlag.value == true) {
                return
            }
            isFlag.value = true
            const timedesc = setInterval(() => {
                descNum.value -= 1
            }, 1000);
            setTimeout(() => {
                isFlag.value = false
                descNum.value = 120
                clearInterval(timedesc)
            }, 12000)

            let params = {}
            params.cardId = item.cardId
            // params.
            proxy.$get('/v3/member/front/balanceRecharge/sendSMS2', params).then(res => {
                if (res.state == 200) {
                    ElMessage.success(res.msg)
                } else {
                    ElMessage.warning(res.msg)
                }
            })

        }
        // 激活
        const bankMessage = ref()
        const activationCard = () => {
            
            let params = {}
            isgoBnak.value = 4
            console.log(isgoBnak.value = 4)
            params.code = bankMessage.value
            params.cardId = bankFlag.value
            proxy.$get('v3/member/front/balanceRecharge/agreePaySignature', params).then(res => {
                if (res.state == 200) {
                    descNum.value = 120
                    ElMessage.success(res.msg)
                    router.push('/member/bankCard')
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        // 提交
        const bindCard = () => {
            if (!bankForm.value.payerAcctNo.trim() && bankForm.value.payerAcctNo.trim().length<8) { return ElMessage.warning('请输入正确的账号') }
            if (!bankForm.value.payerAcctName) { return ElMessage.warning('请输入付款户名') }
            if (!bankForm.value.payerGlobalType) { return ElMessage.warning('请选择证件类型') }
            if (!bankForm.value.payerGlobalId) { return ElMessage.warning('请输入证件号码') }
            let param = {}
            param = bankForm.value
            param.messageCheckCode = messageCheckCode.value
            // param = 
            // param.cardId = 111111
            proxy.$post('/v3/member/front/balanceRecharge/agreePaySignature', JSON.stringify(param), "json").then(res => {
            // proxy.$post('/v3/member/front/balanceRecharge/agreePaySignature', param).then(res => {
                if (res.state == 200) {
                    ElMessage.success(res.msg)
                    router.push('/member/recharge')
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        // 选择银行卡
        let bankFlag = ref()
        const checkBank = (item) => {
            bankFlag.value = item.cardId
        }
        //去支付
        const payComplete = ref(false)
        const pay = () => {
            let param = {};
            if (memberInfo.value.userType != 1) {
                console.log(bankListSelf.value)
                if (bankListSelf.value.length > 0) {
                    isgoBnak.value = 2
                    return
                } else {
                    isgoBnak.value = 3
                    return
                }
                if (!current_pay_method.data.payMethod) {
                    ElMessage.warning("请选择支付方式");
                    return;
                }

                param.payType = current_pay_method.data.payType;
                param.payMethod = current_pay_method.data.payMethod;
                param.paySn = pay_info.data.paySn;

                //余额支付
                if (param.payMethod == 'balance') {
                    if (password.value == "") {
                        ElMessage.warning("请输入支付密码");
                        return;
                    }
                    param.payPwd = password.value; //支付密码,使用余额时必传
                }
            } else {
                console.log(1111111111111)
                if (!bankCode.value) {
                    ElMessage.warning("请选择银行卡");
                    return
                }
                // if (!bankNum.value) {
                //     ElMessage.warning("请输入银行卡号");
                //     return
                // }
                // 企业支付

                param.payType = 'B2B';
                param.payMethod = 'B2B';
                param.paySn = pay_info.data.paySn;
                param.bankCode = bankCode.value;
                // param.bankCardNo= '';
                console.log(param)
            }

            //请求支付接口
            proxy.$post("v3/business/front/orderPay/doPay", param).then(res => {
                if (res.state == 200) {
                    document.write(res.data.payData);
                    if (param.payMethod == 'balance') {
                        ElMessage.success('支付成功,2s后自动跳转订单列表');
                        payComplete.value = true
                        //清除定时器
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 2000)
                    } else if (param.payMethod == 'alipay') {
                        document.write(res.data.payData);//自动提交表单数据
                    } else if (param.payMethod == 'wx') {
                        show_wx_pay.value = true;
                        wxPayQrImg.value = 'data:image/png;base64,' + res.data.payData;//微信支付二维码
                        // 定时查询是否支付成功
                        timer.value = setInterval(() => {
                            queryPayState();
                        }, 3000)
                        //两小时自动跳转订单列表
                        setTimeout(() => {
                            router.replace({
                                path: '/member/order/list',
                            });
                        }, 432000000);
                    }
                } else {
                    ElMessage(res.msg);
                }
            })
                .catch(() => {
                    //异常处理
                });
        };
        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        }
        //隐藏微信支付内容
        const hideWxPay = () => {
            show_wx_pay.value = false;
        };
        //刷新微信支付二维码
        const refreshWxQrcode = () => {
            pay();
        };
        //收起展开详情
        const showDetail = () => {
            show_detail.value = !show_detail.value
        }
        onMounted(() => {
            paySn.value = route.query.paySn;
            ifcart.value = route.query.ifcart
            getPayMethod();
            getInfo()
            // getPayInfo();
        });
        const getInfo=()=>{
      
            proxy.$get('/v3/member/front/member/getInfo').then(res => {
                if (res.state == 200) {
                    bankForm.value.payerReserverMobile = res.data.memberMobile
                }
            })
        }
        const goRecharge = () => {
            router.push('/member/recharge')
        }
        const forgetPassword = () => {
            router.push('/member/pwd/reset')
        }
        onUnmounted(() => {
            if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
            }
        });
        // 获取银行列表
        const bankNum = ref() //银行卡号
        let bankList = ref([])
        const getBankList = () => {
            proxy.$get('/v3/business/front/orderPay/payB2bBank').then(res => {
                if (res.state == 200) {
                    // ElMessage.success(res.msg)
                    bankList.value = res.data
                    console.log(bankList.value)
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }
        getBankList()
        const ischecked = ref('')
        const bankCode = ref(null) //bankcode
        const changeBnak = (item) => {
            console.log(item.code)
            ischecked.value = item.code
            bankCode.value = item.code
        }
        // 选择银行卡后支付 个人
        let payPwd = ref('')
        const getTestNumss = (item) => {
            if (isFlag.value == true) {
                return
            }
            isFlag.value = true
            const timedesc = setInterval(() => {
                descNum.value -= 1
            }, 1000);
            setTimeout(() => {
                isFlag.value = false
                descNum.value = 120
                clearInterval(timedesc)
            }, 12000)

            let param = {}
            param.paySn = paySn.value
            param.cardId = bankFlag.value
            // param.password = payPwd.value
            proxy.$get('v3/member/front/balanceRecharge/sendShortMessage', param).then(res => {
                if (res.state == 200) {
                    ElMessage.success(res.msg)
                } else {
                    ElMessage.warning(res.msg)
                }
            })

        }
        const testnum = ref(null)
        const payNow = () => {
            console.log(bankFlag.value)
            let param = {}
            param.paySn = paySn.value
            param.cardId = bankFlag.value
            param.password = Encrypt(payPwd.value) 
            param.code = testnum.value
            proxy.$post('/v3/member/front/balanceRecharge/pay', param).then(res => {
                if (res.state == 200) {
                    ElMessage.success(res.msg)
                } else {
                    ElMessage.warning(res.msg)
                }
            })
        }

        return {
            // pay_method,
            // // balance,
            // // otherPay,
            // // ali,
            // // wechat,
            // // pay_info,
            // // current_pay_method,
            // // balance_list,
            // // other_pay_list,
            // // changePayMethod,
            // // buy_name_list,
            // // password,
            // // pay,
            // wx_pay_tip,
            // show_wx_pay,
            // hideWxPay,
            // refreshWxQrcode,
            // wxPayQrImg,
            // goRecharge,
            // forgetPassword,
            // showDetail,
            // show_detail,
            // hasPayMethod,
            // configInfo,
            // defaultImg,
            memberInfo,
            payComplete,
            bankForm, //调试用的表单字段,
            bankList,
            changeBnak,
            ischecked,
            bankNum,
            bankCode,
            bankListSelf,
            isgoBnak,
            getTestNum,
            isFlag,
            descNum,
            messageCheckCode,
            bindCard,
            checkBank,
            bankFlag,
            getTestNums,
            activationCard,
            payNow,
            payPwd,
            getTestNumss,
            testnum,
            bankMessage,
            getInfo
        };
    }
};
</script>
<!-- 签署协议表单样式 -->
<style lang="scss">
.bank_bottom_Form {
    // .el-cascader {
    //     width: 100%;
    // }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
    .el-input__inner{
    line-height: 1px !important;
    }
    .el-input__suffix {
        top: -4px;
    }

    .el-input__inner {
        height: 31px;
    }

    .el-form-item {
        margin-bottom: 3px;

        .el-select {
            width: 100%;
        }
    }
}
</style>
<style scoped lang="scss">
.bankInput {
    position: absolute;
    // height: 30px;
    display: block;
    bottom: -50px;
    left: 100px;

    span {
        margin-right: 10px;
        font-size: 14px;
    }

    .bankInput_con {
        height: 30px;
    }
}

.bankList {
    position: relative;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.activation {
    width: 133px;
    height: 49px;
    line-height: 49px;
    border-radius: 4px;
    background-color: rgba(240, 39, 39, 1);
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
    margin-top: 85px;
}

.card_list {
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 10px;

    .item {
        position: relative;
        margin-right: 10px;
        margin-top: 30px;
    }

    .unbinding {
        position: absolute;
        top: -18px;
        right: 2px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../../assets/selectall.png);
        background-size: contain;
    }
    .unbinding2 {
        position: absolute;
        top: -18px;
        right: 2px;
        width: 15px;
        height: 15px;
        // background-color: red;
        border-radius: 50%;
        background-image: url(../../../assets/not_selected.png);
        background-size: contain;
    }
}

.bank_content {
    margin-left: 20px;

    .bank_content_top {
        height: 50px;
        line-height: 50px;
        color: #1C1C1C;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
    }

    .bank_content_bottom {
        width: 307px;
        height: 142px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        div {
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: SourceHanSansSC-bold;
            font-weight: 600;
            margin-left: 20px;

            &:nth-child(2) {
                margin-top: -20px;
            }

        }
    }
}

.bank_bottom_Form {
    padding-left: 60px;
    position: relative;
    .main{
        // margin-left: 100px;
        .el-form-item{
            margin-left: 150px;
        }
    }
    .timeOut {
        position: absolute;
        top: 5px;
        left: 330px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: $colorMain2;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }
    .itemInput {
        margin-top: 20px;
        height: 20px;
    }
    .timeOut2 {
        position: absolute;
        bottom: 0px;
        // left: 330px;
        right: 30px;
        width: 109px;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(240, 39, 39, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: center;
        line-height: 30px;
    }

    .next {
        width: 90px;
        background-color: $colorMain;
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: #fff;
        cursor: pointer;
        margin-left: 25%;
        margin-top: 40px;
    }
}
</style>
<style lang="scss">
@import "../../../style/pay.scss";
@import "../../../style/base.scss";

.no_payMethod {
    margin: 0 auto;
    width: 1200px;
    height: 560px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 163px;
        height: 114px;
    }

    p {
        margin-top: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}
</style>